import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import selectTheme from './components/selectTheme';
import CssBaseline from '@mui/material/CssBaseline';
import WelcomeScreen from './components/WelcomeScreen';
import Questionnaire from './components/Questionnaire';
import ErrorScreen from './components/errorScreen';  // Import a new error component
import { parse, isValid } from 'date-fns'; // Import date-fns for date parsing
import ReactGA from 'react-ga4'; // Import the react-ga4 library

const receive_params = process.env.REACT_APP_RECEIVE_PARAMS === 'true'; // Convert string to boolean
const client = process.env.REACT_APP_CLIENT;
const GA4_MEASUREMENT_ID = process.env.REACT_APP_GA4_MEASUREMENT_ID;
const theme = selectTheme(client);

function App() {
  const [showQuestionnaire, setShowQuestionnaire] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null); // For storing the error message
  const [params, setParams] = useState({}); // Store the parameters

  useEffect(() => {
    // Initialize Google Analytics
    ReactGA.initialize(GA4_MEASUREMENT_ID); // Replace with your GA4 Measurement ID

    // Track page view
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });

    if (receive_params) {
      // Get parameters from URL
      const searchParams = new URLSearchParams(window.location.search);
      const idParam = searchParams.get('id');
      const dobParam = searchParams.get('dob');
      const genParam = searchParams.get('gen')?.toLowerCase();
      const titParam = searchParams.get('tit');

      // Check if all parameters are received and valid
      if (!idParam || !dobParam || !genParam || !titParam || (genParam !== 'm' && genParam !== 'f')) {
        // If any parameter is missing or invalid, show an error
        const missingParams = [
          !idParam && 'ID',
          !dobParam && 'DOB',
          (!genParam || (genParam !== 'm' && genParam !== 'f')) && 'GEN',
          !titParam && 'TIT',
        ].filter(Boolean).join(', ');
        setErrorMessage(`Missing or invalid parameters: ${missingParams}`);
      } else {
        // Validate date format
        const parsedDob = parse(dobParam, 'dd-MM-yyyy', new Date());
        if (!isValid(parsedDob)) {
          setErrorMessage('Invalid date format. Please use DD-MM-YYYY.');
        } else {
          // If all parameters are received and valid, set them and show the Questionnaire
          setParams({ id: idParam, dob: dobParam, gen: genParam, tit: titParam, restartApp: restartApp });
          setShowQuestionnaire(true);
        }
      }
    }
  }, []);

  const handleContinue = () => {
    setShowQuestionnaire(true);
  };

  const restartApp = () => {
    if (receive_params) {
      // Get parameters from URL
      const searchParams = new URLSearchParams(window.location.search);
      const idParam = searchParams.get('id');
      const dobParam = searchParams.get('dob');
      const genParam = searchParams.get('gen')?.toLowerCase();
      const titParam = searchParams.get('tit');

      // Check if all parameters are received
      if (!idParam || !dobParam || !genParam || !titParam) {
        // If any parameter is missing, show an error
        const missingParams = [
          !idParam && 'ID',
          !dobParam && 'DOB',
          (!genParam || (genParam !== 'm' && genParam !== 'f')) && 'GEN',
          !titParam && 'TIT',
        ].filter(Boolean).join(', ');
        setErrorMessage(`Missing or invalid parameters: ${missingParams}`);
      } else {
        // Validate date format
        const parsedDob = parse(dobParam, 'dd-MM-yyyy', new Date());
        if (!isValid(parsedDob)) {
          setErrorMessage('Invalid date format. Please use DD-MM-YYYY.');
        } else {
          // If all parameters are received, set them and show the Questionnaire
          setParams({ id: idParam, dob: dobParam, gen: genParam, tit: titParam });
          setShowQuestionnaire(false); // Reset the questionnaire view
          setTimeout(() => setShowQuestionnaire(true), 0); // Trigger re-render
        }
      }
    }
  };

  // If receive_params is true and there is an error, render ErrorScreen
  if (receive_params && errorMessage) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ErrorScreen message={errorMessage} /> {/* Show error screen */}
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        {showQuestionnaire ? (
          <Questionnaire {...params} /> /* Pass params to Questionnaire */
        ) : (
          <WelcomeScreen onContinue={handleContinue} />
        )}
      </div>
    </ThemeProvider>
  );
}

export default App;
