import React, { useState, useEffect } from "react";
import { isWithinOperatingHours } from '../utils/operatingHours';
import PinModal from './pinModal';

const CallToAction = ({ jsonDiag, diagnosticId }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isOperating, setIsOperating] = useState(false); // State to check operating hours

    const chatUrl = process.env.REACT_APP_CHAT_URL;
    const getsId = process.env.REACT_APP_GETS_ID; 

    const handleChat = () => {
        setIsModalOpen(true);
    };

    useEffect(() => {
        // Call the function to check operating hours
        const checkOperatingHours = () => {
            const withinHours = isWithinOperatingHours();
            setIsOperating(withinHours); // Set the state based on the result
        };

        checkOperatingHours();
    }, []); // Run once on component mount
    return (
        <>
            {isOperating && (
                <div style={{ marginTop: "20px" }}>
                    <button
                        onClick={(e) => {
                            if (getsId === 'true') {
                                if (diagnosticId?.diagnostic_id) {
                                    window.location.href = `${chatUrl}/${diagnosticId.diagnostic_id}`;
                                } else {
                                    window.location.href = chatUrl;
                                }
                            } else {
                                e.preventDefault();
                                handleChat(); // Open the modal on click
                            }
                        }}
                        style={{ border: 'none', background: 'none', width: '100%', cursor: 'pointer' }}
                    >
                        <img
                            src="/cta.svg"
                            alt="GRATIS: Chatea ahora con un Doctor ahora y comparte estos resultados"
                            width="100%"
                        />
                    </button>

                    {/* Pass the apiResponses to PinModal */}
                    <PinModal
                        open={isModalOpen}
                        handleClose={() => setIsModalOpen(false)}
                        jsonDiag={jsonDiag} // Pass the structured API response
                    />
                </div>
            )}
        </>
    );
};

export default CallToAction;
